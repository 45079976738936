<template>
  <div class="camp-election-template pt-5">
    <page-header class="d-flex align-items-center">
      <h1 class="flex-1 mb-0">{{ translations.tcEditCampElection }}</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <div class="d-flex">
          <div class="col flex-0 w-100-sd">
            <h4 class="mb-2 mb-sm-4">{{ translations.tcCampNumber }}</h4>
            <p class="mb-4 mb-sm-0 pt-2">{{ currentCamp.campnumber }}</p>
          </div>
          <div class="col flex-0 w-100-sd">
            <h4 class="mb-2 mb-sm-4">{{ translations.tcCampName }}</h4>
            <p class="mb-4 mb-sm-0 pt-2">{{ currentCamp.campname }}</p>
          </div>
          <div class="col flex-0 w-100-sd">
            <h4 class="mb-2 mb-sm-4">{{ translations.tcElectionDate }}*</h4>
            <div class="start-input">
              <b-form-datepicker
                id="electionDate"
                v-model="electionDate"
                class="mb-2 contact_date"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                :locale="prefCulture"
                :label-help="translations.tcCalHelp"
                :label-no-date-selected="translations.tcDatePickerPlaceholder"
                :max="maxDate"
                :placeholder="translations.tcDatePickerPlaceholder"
                :show-decade-nav="showDecadeNav"
                :hide-header="hideHeader"
              >
                <template #button-content style="padding: 12px">
                  <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                </template>
              </b-form-datepicker>
            </div>
          </div>
          <div class="col flex-0 w-100-sd">
            <h4 class="mb-2 mb-sm-4">{{ translations.tcConductedBy }}</h4>
            <b-form>
              <b-form-group class="form-element">
                <div class="d-flex">
                  <p v-if="!!contactByName" class="mb-sm-0 p-small mr-1">{{ contactByName }}</p>
                  <p v-else class="mb-sm-0 p-small mr-1">{{ translations.tcNoMemberSelected }}</p>
                  <b-button
                    :name="`findRecommendedByMemberButton`"
                    variant="primary"
                    class="flex-0 mr-0 d-block w-100-sd btn-pl-xsm ml-1"
                    @click="showModal($event, 'personSearchModal')"
                  >
                    {{ translations.tcFind }}
                  </b-button>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </div>
        <hr />
        <p class="section-1-notes">
          <b>{{ translations.tcInstructionsHint }}</b
          ><br />{{ translations.tcOnlyChangeInfoForNewlyElectedOrAppointed }}
        </p>
      </section>
      <section class="section-2 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h3 style="margin-bottom: 20px; color: #000">
            {{ translations.tcGideonElectedOfficers }}
          </h3>
        </header>
        <div class="body">
          <div class="members d-flex">
            <div class="member w-1_3" v-for="(member, index) in mems" :key="index">
              <h3 class="title text-left">{{ member.office }}</h3>
              <div class="name">
                <b-form :class="'w-full'">
                  <b-form-group class="form-element">
                    <b-form-select
                      :disabled="!contactBy || !electionDate"
                      class="form-control g-select w-full"
                      :options="gideonMembersTranslated"
                      :key="index"
                      v-model="member.ind_key"
                      @change="handleMemberChange(member)"
                    ></b-form-select>
                  </b-form-group>
                </b-form>
              </div>
              <div v-if="member.name.toLowerCase() !== 'unfilled'" class="start-date">
                {{ translations.tcStartDate }}:
                <b-form :class="'w-full'">
                  <b-form-group class="form-element">
                    <b-form-datepicker
                      :id="`mem-start-date-` + member.ind_key + '-' + index"
                      v-model="mems[index].start_date"
                      class="mb-2 contact_date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      :disabled="!contactBy || !electionDate"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :max="maximumStartDate"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                      size="sm"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-3 bg-block mb-4">
        <header class="toggle-header mb-3">
          <h3 style="margin-bottom: 20px; color: #000">
            {{ translations.tcAuxiliaryElectedOfficers }}
          </h3>
        </header>
        <div class="body">
          <div class="members d-flex">
            <div class="member w-1_3" v-for="(aux, index) in auxs" :key="index">
              <h3 class="title text-left">{{ aux.office }}</h3>
              <div class="name">
                <b-form :class="'w-full'">
                  <b-form-group class="form-element">
                    <b-form-select
                      :disabled="!contactBy || !electionDate"
                      class="form-control g-select w-full"
                      :options="auxiliaryMembersTranslated"
                      :key="index"
                      v-model="aux.ind_key"
                      @change="handleMemberChange(aux)"
                    ></b-form-select>
                  </b-form-group>
                </b-form>
              </div>
              <div v-if="aux.name.toLowerCase() !== 'unfilled'" class="start-date">
                {{ translations.tcStartDate }}:
                <b-form :class="'w-full'">
                  <b-form-group class="form-element">
                    <b-form-datepicker
                      :id="`aux-start-date-` + aux.ind_key + '-' + index"
                      v-model="auxs[index].start_date"
                      class="mb-2 contact_date"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      :disabled="!contactBy || !electionDate"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :max="maximumStartDate"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                      size="sm"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-4 pt-4">
        <div class="body">
          <div class="d-flex justify-content-center">
            <b-button
              :disabled="!contactBy || !electionDate"
              variant="primary"
              class="flex-0 mr-0 mr-sm-3 d-block w-100-sd mb-3 mb-sm-0"
              @click="handleSaveClick()"
            >
              {{ translations.tcSave }}
            </b-button>
            <b-button
              @click="handleCancelClick()"
              variant="tertiary"
              class="flex-0 mr-0 mr-sm-3 d-block w-100-sd mb-3 mb-sm-0"
            >
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :members_only_flag="1"
      :not_a_member="0"
      :camp_country="userCountryKey"
      :member_type_key="this.member_type_key"
      :i18n="translations.components"
    ></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import constantData from '@/json/data.json'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-camp-election',
  mixins: [translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      auxiliaryMembersTranslated: [],
      gideonMembersTranslated: [],
      translations: {},
      toggle: true,
      max: null,
      contactBy: null,
      contactByName: null,
      auxs: [],
      mems: [],
      electionDate: null,
      maxDate: this.formatForDatePicker(new Date(), 'date'),
    }
  },
  methods: {
    ...mapActions({
      getElectionAuxiliaryMembers: 'election/getElectionAuxiliaryMembers',
      getElectionCommittee: 'election/getElectionCommittee',
      getElectionCommitteeMembers: 'election/getElectionCommitteeMembers',
      getElectionGideonMembers: 'election/getElectionGideonMembers',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMemberType: 'election/setMemberType',
      updateElectionCommittee: 'election/updateElectionCommittee',
      upsertElectionCommitteeMember: 'election/upsertElectionCommitteeMember',
    }),
    getNow() {
      const today = new Date()
      this.max = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    },
    handleCancelClick() {
      this.$router.push({ name: 'view-camp-election' })
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getElectionCommitteeMembers(),
          await this.getElectionGideonMembers(),
          await this.getElectionAuxiliaryMembers(),
          await this.getElectionCommittee(),
          this.getNow(),
        ]).then(() => {
          this.updateTranslatedOfficeTitles(['gideonMemberDetails', 'auxiliaryMemberDetails'])
          this.updateTranslatedMemberTypes()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    updateTranslatedMemberTypes() {
      // sets this.auxs/mems and translates the 'Unfilled' entry of the member lists
      this.auxs = [...this.auxiliaryMemberDetails]
      this.mems = [...this.gideonMemberDetails]
      const memberTypes = ['gideonMembers', 'auxiliaryMembers']
      memberTypes.forEach((memberType) => {
        this[`${memberType}Translated`] = this[memberType].map((member) => {
          return member.sort_name.toLowerCase() === 'unfilled' || member.text.toLowerCase() === 'unfilled'
            ? { ...member, text: this.translations.tcUnfilled || 'Unfilled' }
            : member
        })
      })
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    handleMemberChange(member) {
      let mbr = [...this.auxiliaryMembersTranslated, ...this.gideonMembersTranslated].find(
        (gaf) => gaf.value === member.ind_key
      )
      member.name = mbr.text
      member.sort_name = mbr.sort_name
      member.start_date = this.electionDate
    },
    async handleSaveClick() {
      try {
        if (!!this.contactBy && !!this.electionDate) {
          this.setLoadingStatus(true)
          let electionResults = [...this.mems, ...this.auxs].map((memRec) => {
            return {
              cmt_key: memRec.cmt_key,
              cmt_org_key: memRec.cmt_org_key,
              cty_key: memRec.cty_key,
              ind_key: memRec.ind_key,
              committee_name: memRec.committee_name,
              name: memRec.name,
              sort_name: memRec.sort_name,
              start_date: memRec.start_date,
              term_number: memRec.term_number,
              vctp_ctp_hierarchy: memRec.vctp_ctp_hierarchy,
              cxp_key: memRec.cxp_key,
              cxi_key: memRec.cxi_key,
              mbt_met_key: memRec.mbt_met_key,
              edit_user: this.userLogin.acountid,
            }
          })
          let electionResultsResponse = await this.upsertElectionCommitteeMember(electionResults)
          if (electionResultsResponse === false) {
            await this.$swal({
              icon: 'error',
              text: this.translations.tcErrorUpdatingMembers,
              confirmButtonText: this.translations.tcOk || 'Ok',
            })
            throw 'error updating members'
          }
          let electionData = [
            {
              cmt_key: this.mems[0].cmt_key,
              ind_key: this.contactBy,
              start_date: this.electionDate,
              edit_user: this.userName,
            },
            {
              cmt_key: this.auxs[0].cmt_key,
              ind_key: this.contactBy,
              start_date: this.electionDate,
              edit_user: this.userName,
            },
          ]
          let electionDataResponse = await this.updateElectionCommittee(electionData)
          if (electionDataResponse === false) {
            this.$swal({
              icon: 'error',
              text: this.translations.tcErrorUpdatingCommittee,
              confirmButtonText: this.translations.tcOk || 'Ok',
            })
            throw 'error updating committee'
          } else {
            this.$swal({
              icon: 'success',
              text: this.translations.tcSuccessUpdatingCommitteeAndMembers,
              confirmButtonText: this.translations.tcOk || 'Ok',
            })
            this.$router.push({ path: '/officers/camp/election/view-camp-election' })
            await this.pageLoad()
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async applyFoundPerson(evt) {
      switch (this.findButton) {
        case 'findRecommendedByMemberButton':
          this.contactBy = evt.ind_key
          this.contactByName = evt.name_text
          break
        default:
          console.error('Default person, why are we here?')
          break
      }
    },
    prepTheDate(theDate) {
      let [yyyy, mm, dd] = theDate.split('-')
      return new Date(parseInt(yyyy, 10), parseInt(mm, 10) - 1, parseInt(dd, 10), 6, 0, 0)
    },
    handleOfficerDateSelected(evt, memAux, index) {
      evt.setHours(1, 0, 0, 0)
      let nd = [
        evt.getFullYear(),
        (evt.getMonth() + 1 < 10 ? '0' : '') + (evt.getMonth() + 1),
        (evt.getDate() < 10 ? '0' : '') + evt.getDate(),
      ]
      let offArr = memAux === 'mem' ? this.mems : this.auxs
      offArr[index].start_date = nd.join('-')
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      const memberType = constantData.member_types.find((x) => x.text === 'Gideon')
      // private non-reactive-variable
      this.member_type_key = memberType ? memberType.value : null
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('person-search'),
        this.getComponentTranslations('common.officer-titles'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.stripReadableText(results[2])
        this.updateTranslatedOfficeTitles(['gideonMemberDetails', 'auxiliaryMemberDetails'])
      })
      await this.pageLoad()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      auxiliaryMembers: 'election/auxiliaryMembersSelect',
      auxiliaryMemberDetails: 'election/auxiliaryMemberDetailSelect',
      campNumber: 'user/userCampNumber',
      committeeDetails: 'election/committeeDetailSelect',
      gideonMembers: 'election/gideonMembersSelect',
      gideonMemberDetails: 'election/gideonMemberDetailSelect',
      officerToolbarActive: 'user/officerToolbarActive',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userCountryKey: 'user/userCountryKey',
      userLocation: 'user/userLocation',
      userLogin: 'user/userLogin',
      userName: 'user/userName',
    }),
    currentCamp() {
      if (this.officerToolbarActive) {
        return { campname: this.officerToolbarSelected.camp_name, campnumber: this.officerToolbarSelected.camp_number }
      } else {
        return { campname: this.userLogin.campname, campnumber: this.userLogin.campnumber }
      }
    },
    maximumStartDate() {
      if (!!this.electionDate) {
        let edate = this.electionDate.split('-')
        return new Date(edate[0], parseInt(edate[1], 10) - 1, edate[2])
      }
      return new Date()
    },
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-body {
  padding: 40px 15px 80px 15px;
}

.bg-block {
  padding: 45px 40px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1,
.section-2,
.section-3 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
    .col {
      @include breakpoint(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.section-1 {
  .d-flex {
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .time-select-1 {
    .start-end-app .start-input {
      @include breakpoint(sm) {
        &:before {
          content: '';
          z-index: 1;
        }
      }
      input {
        padding: 11px 20px 10px 60px;
      }
      .placeholder {
        font-size: 16px;
        color: #636363;
      }
    }

    #start-date {
      padding: 10px 20px 11px 60px;
    }
  }
  p {
    font-size: 20px;
  }
  p.p-small {
    font-size: 20px;
  }
  .col {
    &:nth-of-type(1) {
      width: 20%;
    }
    &:nth-of-type(2) {
      width: 20%;
    }
    &:nth-of-type(3) {
      width: 25%;
    }
    &:nth-of-type(4) {
      width: 35%;
    }
  }
  select {
    max-width: none !important;
    width: 178px;
  }
  input[type='text'] {
    height: auto;
    line-height: 20px;
  }
}

.section-1-notes {
  min-height: 87px;
  @include breakpoint(sm) {
    min-height: 66px;
  }
}

hr {
  margin: 56px 0 34px;
  @include breakpoint(sm) {
    margin: 40px 0;
  }
}
.date-select-1 {
  .start-end-app .start-input {
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
    input {
      padding: 11px 20px 10px 60px;
    }
    .placeholder {
      color: #636363;
    }
  }
}
form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.section-2,
.section-3 {
  padding-bottom: 15px;
}

.members {
  flex-wrap: wrap;
  .member {
    max-width: 33.3333%;
    padding-right: 20px;
    margin-bottom: 40px;
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
    }
    h3 {
      margin-bottom: 28px;
      font-family: $open-sans;
      font-size: 18px;
      letter-spacing: 0.6px;
      line-height: 24px;
    }
    div {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
      svg {
        margin-right: 16px;
      }
    }
    .g-select {
      max-width: 100%;
      @include breakpoint(sm) {
        max-width: 100%;
      }
    }
  }
}

.section-4 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

.start-date,
.term-number {
  text-transform: uppercase;
}

.title {
  font-weight: 700;
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
